.sidebar {
    display: none;
}

.sidebar {
    width: 16.8rem;
    left: 0px;
    top: 0px;
    background: #08783D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 38px;
    padding-bottom: 8px;
    position: fixed;
    transition: width 0.3s ease;
    z-index: 800;
    height: 100%;
}

.sidebar ul {
    list-style: none;
}

.sidebar .logout {
    cursor: pointer;
}
.sidebar a,
.sidebar .logout {
    text-decoration: none;
    padding: 17px;
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #EBF5F0;
}

.link-text {
    margin-left: 17px;
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.menu-item {
    width: 230px;
    /* width: 205px; */
    /* height: 48px; */
    padding-top: 30px;
    /* padding-bottom: 30px; */
}

.menu-item.active {
    background: #FFFFFF;
    background: rgba(235, 245, 240, 0.2);
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    /* padding: 11px 17px 11px 0px; */
    margin-top: 30px;
    padding: 10px 0px 10px 0px;
}

.onmobile {
    display: none;
}

.ondesktop {
    display: block;
}

.menu-item.active svg,
.menu-item.active .link-text {
    /* filter: invert(45%) sepia(8%) saturate(1044%) hue-rotate(183deg) brightness(93%) contrast(85%); */
}
.sidebar-divider{
    width: 230px;
height: 1px;
background: #EBF5F0;
}
.sidebar-divider-wrapper {
    margin-bottom: -8px;
}
@media (min-width: 992px) {
    .sidebar {
        position: fixed;
        z-index: 105;
        top: 0;
        bottom: 0;
        left: 0;
        transition: width 0.3s ease;
    }

    .logo-container {
        display: none;
    }

    .menu {
        margin-top: 20px;
    }

    .onmobile {
        display: block;
    }

    .ondesktop {
        display: none;
    }
}

@media (max-width: 991px) {
    .sidebar {
        display: none;
    }

    .display-item {
        display: flex;
    }

    .main-content {
        margin-left: 0px;
    }

    .logo-container {
        display: block;
    }
}

.mobile-sidebar {
    position: fixed;
    overflow: hidden;
    /* background-color: rgba(138, 187, 42, 0.3); */
    background-color: rgba(0, 0, 0, 0.2);
    ;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
}