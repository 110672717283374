.settings .text {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #475467;
}
.settings .sub-text {
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 120%;
display: flex;
align-items: center;
color: #98A2B3;
/* margin-top: 1rem; */
}
.settings .btn-settings {
    padding: 12px 16px;
gap: 8px;
border: 1px solid #EAECF0;
border-radius: 8px;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 120%;
color: #667085;
}
.settings .avater{
    width: 86px;
height: 86px;
background: #04487F;
border-radius: 50%;
font-weight: 400;
font-size: 43px;
line-height: 120%;
color: #fff;
display: flex;
justify-content: center;
align-items: center;
}

.more-btn.settings-save-btn {
    padding: 0px 12px;
}

@media (min-width: 992px){
    .more-btn.settings-save-btn {
        padding: 7px 64px;
    }
    .more-btn-outline.settings-cancel-btn {
        padding: 7px 34px;
    }
}