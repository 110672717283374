.ant-drawer .ant-drawer-header {
    height: 100px;
    background: linear-gradient(45deg, #043C1F 0%, #08783D 100%);
    align-items: flex-start;
}
.drawer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}
.drawer-wrapper {
    width: 200;
}
.drawer .title { 
    font-family: 'Komet';
font-style: normal;
font-weight: 800;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
color: #FFFFFF;
}
.drawer .subtitle {
    font-family: 'Komet';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
color: #FFFFFF;
}
.ant-drawer .ant-drawer-body::-webkit-scrollbar{
    display: none;
}
.ant-drawer .ant-drawer-body {
    padding-top: 0;
    padding-bottom: 10px;
}
.drawer ul {
      
        width: 21rem;
        height: 46px;
        align-items: center;
        padding: 3px 4px;

        background: #F9FAFB;
border: 1px solid #F2F4F7;
border-radius: 8px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: #FFFFFF;
box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
border-radius: 6px;
font-family: 'Komet';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #344054;
}
.nav-link, .nav-link:hover, .nav-link:focus {
    font-family: 'Komet';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #667085;
}

.nav-link {
    padding: 0.6rem 1rem;
}
@media (min-width: 992px){
    .drawer-wrapper {
        width: 500;
    }
}