
.signin-card {
    /* padding: 64px 48px;  */
    padding: 51px 27px 30px;
    background: #FFFFFF;
box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
border-radius: 20px;
max-width: 28rem;
}
.signin .title {
    font-family: 'Abel';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 38px;
text-align: center;
color: #1D2939;
}
.signin .subtitle {
    font-family: 'Komet';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #667085;
}
.signin .signin-btn {
background: #08783D;
border-radius: 10px;
}
.mask-password {
    margin-bottom: 0;
}
.no-account {
    font-family: 'Komet';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #667085;

}
.forget-password {
    font-family: 'Komet';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
    color: #08783D;
    text-decoration: none;
}
@media (min-width: 992px) {
    .signin-card {
        padding: 38px 30px 23px 30px;
        max-width: 28rem;
    }
    .mask-password p {
        margin-bottom: 0;
    }
}