body {
    background: #FCFCFD;
}

.main-content {
    margin-left: 0px;
}

.dashboard-header {
    background: #FCFCFD;
    background: #FFFFFF;
    border-bottom: 1px solid #EAECF0;
}

.text-avater {
    height: 32px;
    width: 32px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #04487F;
    color: #FFFFFF;
}

.navbar-nav {
    flex-direction: row;
}

.sidebar-user .title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.sidebar-user .sub-title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #F2F4F7;

}

.user-container {
    display: none;
}

.notification {
    padding: 24px 16px;
}

.dropdown-menu {
    margin-top: 20px;
}

.progress {
    height: 8px;
    background: #F2F4F7;
    border-radius: 4px;
}

.verify {
    cursor: pointer;
}

.verify p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 120%;
    color: #8ABB2A;
    margin-right: 14px;
}

.main-content .welcome {
    display: flex;
}

.progress-bar {
    background-color: #8ABB2A;
}

.welcome .title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 120%;
    align-items: center;
    color: #101828;
}

.welcome .subtitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #475467;
    margin-top: 16px;
    margin-bottom: 64px;
}

.welcome-card .title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #101828;
    margin-bottom: 24px;
}

.welcome-card .desc {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #98A2B3;
    /* margin-top: 28px; */
}

.welcome-card {
    height: 149px;
    border: 1px solid #EAECF0;
    border-radius: 16px;
    padding: 24px;
}

.form-btn-outline {
    color: #101828;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    text-decoration: none;
    padding: 6px 17px !important;
}

.rule {
    border: 1px solid #EAECF0;
}

.form-btn-outline:hover,
.btn.form-btn-outline.active,
.btn.form-btn-outline:active {
    background-color: #8ABB2A;
    color: #fff;
}


.upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 44px;
    border-radius: 7px;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 14px;
    margin-bottom: 20px;
}

.upload:before {
    content: "";
    position: absolute;
    border: 4px dashed #D0D5DD;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
}

.upload.odd:before {
    left: -1;
    right: -1;
    border-radius: 8px
}

.upload span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: right;
    color: #D0D5DD;

    max-width: 135px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.info .progress-text {
    width: 30%;
}

.info .progress-line {
    width: 70%;
}

.upload-file {
    cursor: pointer;
    position: relative;
}

.upload input[type="file"] {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    cursor: pointer;
}

.pin input,
.code input {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #475467;
}

.transaction-pin .info {
    height: 40px;
    background: rgba(138, 187, 42, 0.1);
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #475467;
}

.ant-upload-list .ant-upload-list-text {
    margin-bottom: 1rem;
}

.ant-upload-drag {
    background-color: red;
}

.rounded-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    /* width: 36px;
height: 36px; */

    background: #08783D;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.metric-card {
    border-radius: 8px;
}

.total {
    background: url('../../public/assets/images/total.svg') no-repeat right, #EBF5F0;
    border: 1px solid #B5D7C5;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.closed {
    background: url('../../public/assets/images/closed.svg') no-repeat right, #FFFCF5;
    border: 1px solid #FEDF89;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.opened {
    background: url('../../public/assets/images/opened.svg') no-repeat right, #FAFAFF;
    border: 1px solid #DEDCF1;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.metric-card .label {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
}

.metric-card .amount {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #101828;
}
.chart-filter {
    height: auto;
}
.chart-filter h1 {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
}

.chart-filter .range-label label {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #344054;
    border-color: #D0D5DD;

}

.btn-check:checked+.rangelabel,
:not(.btn-check)+.rangelabel:active,
.rangelabel:first-child:active,
.rangelabel.active,
.rangelabel.show {
    color: #1D2939;
    background-color: #D0D5DD;
    border-color: #D0D5DD
}

.btn-check+.rangelabel:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: #08783D;
    border-radius: 20px;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: #08783D !important;
    border-radius: 20px !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background-color: #EBF5F0 !important;
    /* border-radius: 20px !important; */
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #EBF5F0 !important;
}

.datepicker-btn {
    padding: 10px 16px;
    width: 109px;
    height: 40px;
    background: #08783D;
    border: 1px solid #08783D;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.datepicker-btn-outline {
    padding: 10px 16px;
    width: 109px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.dcard {
    background: #FFFFFF;
    border: 1px solid #F2F4F7;
    border-radius: 16px;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);

}

.dcard-header {
    margin-bottom: 0;
    color: var(--bs-card-cap-color);
    background-color: #fff;
    border-bottom: 1px solid #F2F4F7;
}

.chart-container {
    position: relative;
    margin: auto;
    /* height: 64vh;
    width: 64vw; */
}

.activities {
    height: 477px;
    overflow: auto;
}
.activities::-webkit-scrollbar {
    display: none;
}

.activities .avater-wrapper {
    width: 48px;
    height: 48px;
    background: #EBF5F0;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activities .heading {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
}

.activities .sub-heading {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
}

.activities span {
    font-family: 'Komet';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #667085;
    white-space: nowrap;
}
.mobile {
    display: none;
}
.sort {
    cursor: pointer;
}
.sort-wrapper .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #98A2B3;
}
.dropdown-menu {
    margin-top: 20px;
}
.sort-wrapper {
    border: 0.5px solid #D0D5DD;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 6px;
}
.search-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 8px;
    /* width: 271px; */
    border: 0.5px solid #D0D5DD;
    border-radius: 4px;
}
.search-wrapper input {
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
}
.ant-table-tbody .ant-table-row:nth-child(even) {
    background-color: #F9FAFB;
  }
  .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td {
    border-inline-end: 1px solid #f0f0f0;
    }
.table-card {
background: #FFFFFF;
border: 1px solid #F2F4F7;
box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
border-radius: 8px;
}
.page-text:hover {
    color: #08783D;
}
.page-text:active, .page-text{
    color: #667085
}
.page-text[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}
.dropdown-menu {
    padding: 0;
}
.dropdown-item {
    padding: 9px;
}
.dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: #F9FAFB;
}
.ant-pagination .ant-pagination-item-active {
    font-style: normal;
font-weight: 500;
font-size: 14px;
    border-color: #EBF5F0;
    background: #EBF5F0;
border-radius: 8px;
}
.ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    align-items: center;

  }
  .ant-pagination-prev,
  .ant-pagination-next .ant-pagination-item-link {
   
  }
  .complaint-wrapper {
    padding: 0 15px;
  }
  .ant-table-wrapper .ant-table-thead >tr>th {
    text-wrap: nowrap;
  }
  .search-wrapper{
    width: 100%;
  }
@media (min-width: 992px) {
    .search-wrapper {
        width: 271px;
    }
    .complaint-wrapper {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .mobile {
        display: flex;
    }
    .chart-filter {
        height: 72vh;
    }
    .chart-container {
        position: relative;
        margin: auto;
        height: 64vh;
        width: 64vw;
    }
    .info .progress-text {
        width: 25%;
    }

    .info .progress-line {
        width: 75%;
    }

    .main-content .welcome {
        display: flex;
        padding-left: 90px;
    }

    .welcome .title {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 21px;
        line-height: 120%;
        align-items: center;
        color: #101828;
    }

    .welcome .subtitle {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        align-items: center;
        color: #475467;
        margin-top: 16px;
        margin-bottom: 50px;
    }

    .welcome-card {
        height: 149px;
        border: 1px solid #EAECF0;
        border-radius: 16px;
        padding: 24px;
    }

    .welcome-card .title {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #101828;
        margin-bottom: 24px;
    }

    .verify p {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #8ABB2A;
        margin-right: 14px;
    }

    .welcome-card .desc {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #98A2B3;
        /* margin-top: 28px; */
    }

    .notification {
        padding: 14px 16px 4px;
        position: relative;
        width: 350px;
        min-height: 163px;
        max-height: 300px;
        background: #FFFFFF;
        box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
        border-radius: 8px;
    }

    .user-view-profile {
        width: 207px
    }

    .user-view-profile span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
        margin-left: 12px;

    }

    .notification p {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #101828;
    }
.notification .message {
    background-color: #EBF5F0;
    border: 1px solid #B5D7C5;
border-radius: 8px;
padding: 12px;
}
.notification .notify {
    background-color: #fff;
    border: 1px solid #F2F4F7;
    border-radius: 8px;
padding: 12px;
}
.featured {
    align-items: center;
}
    .featured-img {
        width: 48px;
        height: 48px;
        background: #82A4BF;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .featured-text .title {

        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: #101828;
    }

    .featured-text .subtitle {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #667085;
    }

    .dropdown-item .time {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 120%;
        text-align: right;
        color: #667085;
    }

    .notification .dropdown-item {
        padding: 14px 16px;
        width: 100%;
        height: 63px;
        background: #F9FAFB;
        border-radius: 4px;
    }

    .user-container {
        display: flex;
    }

    .dashboard-header {
        /* padding: 30px 64px; */
        padding: 20px 54px 20px 40px;
        height: 74px;
    }

    .main-content {
        margin-left: 16.8rem;
    }

    .user .title {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        display: flex;
        align-items: center;
        color: #101828;
        white-space: nowrap;
    }

    .user span {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        display: flex;
        align-items: center;
        color: #98A2B3;

    }
}